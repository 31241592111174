import React from 'react';
import './App.css';
import emailjs from 'emailjs-com';
import Contact from './Contact';

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: '',
            phone: false,
            email: true,
            contact: false,
            therapists: ['Unsure']
        }
        this.handlePhone = this.handlePhone.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleContact = this.handleContact.bind(this);
        this.handleTherapist = this.handleTherapist.bind(this);
    }

    handlePhone() {
        this.setState({
            phone: !this.state.phone
        })
    }

    handleEmail() {
        this.setState({
            email: !this.state.email
        })
    }

    handleContact() {
        this.setState({
            contact: !this.state.contact
        })
    }

    handleTherapist(name) {
        if (this.state.therapists.includes(name)) {
            this.setState({
                therapists: this.state.therapists.filter(t => t !== name)
            });
            if (this.state.therapists.length === 1) {
                this.setState({
                    therapists: ['Unsure']
                })
            }
        }
        else {
            let newState = this.state.therapists.concat(name);
            if (name === "Unsure") {
                newState = newState.filter(t => t === "Unsure")
            }
            else {
                newState = newState.filter(t => t !== "Unsure")
            }

            this.setState({
                therapists: newState
            });
        }
    }

    sendEmail = (e) => {
        e.preventDefault();
        if (this.state.phone === false && this.state.email === false) {
            this.setState({
                message: 'Please select at least one method of contact.'
            });
        }
        else {
            this.setState({
                message: 'Sending message...'
            });
            console.log(e.target);
            emailjs.sendForm('service_hdyjw5q', 'template_zjvyee5', e.target, 'user_ebmznMDN64Ho5dSu05f3i')
                .then((result) => {
                    console.log(result.text);
                    this.setState({
                        message: 'Thank you for your message. I will respond to you within 24 hours.'
                    });
                }, (error) => {
                    console.log(error.text);
                    this.setState({
                        message: 'Error sending email. Please try again later.'
                    });
                });
            e.target.reset();
        }
    }

    render() {

        const phoneContent = this.state.phone
            ? <div><input type="tel" placeholder="Phone Number" className="contact-input" name="phone" required /></div>
            : null;

        const emailContent = this.state.email
            ? <div><input type="email" placeholder="Email" className="contact-input" name="email" required /></div>
            : null;

        return (
            <div>
                <div className="contact-form">
                    <form onSubmit={this.sendEmail.bind(this)}>
                        <div><input type="text" placeholder="Name" className="contact-input" name="name" required /></div>
                        <div className="contact-method">
                            <div class="checkbox-title">Therapist(s) You'd Like to Connect With:</div>
                            <div><input type="checkbox" className="contact-checkbox" checked={this.state.therapists.includes('Unsure')} onChange={() => this.handleTherapist('Unsure')} />Unsure</div>
                            <div><input type="checkbox" className="contact-checkbox" checked={this.state.therapists.includes('Tracy Bertrim')} onChange={() => this.handleTherapist('Tracy Bertrim')} />Tracy Bertrim</div>
                            <div><input type="checkbox" className="contact-checkbox" checked={this.state.therapists.includes('Maya Oakes')} onChange={() => this.handleTherapist('Maya Oakes')} />Maya Oakes</div>
                            <div><input type="checkbox" className="contact-checkbox" checked={this.state.therapists.includes('Stacey Gudmundsson')} onChange={() => this.handleTherapist('Stacey Gudmundsson')} />Stacey Gudmundsson</div>
                            <div><input type="checkbox" className="contact-checkbox" checked={this.state.therapists.includes('Solmaz Barghgir')} onChange={() => this.handleTherapist('Solmaz Barghgir')} />Solmaz Barghgir</div>
                            <div><input type="checkbox" className="contact-checkbox" checked={this.state.therapists.includes('Mycha Haughton-Lewis')} onChange={() => this.handleTherapist('Mycha Haughton-Lewis')} />Mycha Haughton-Lewis</div>
                            <input type='hidden' value={this.state.therapists.toString()} name='therapists' />
                        </div>
                        <div className="contact-method">
                            <div>Contact Method:</div>
                            <div><input type="checkbox" className="contact-checkbox" checked={this.state.email} onChange={this.handleEmail} />Email</div>
                            <div><input type="checkbox" className="contact-checkbox" checked={this.state.phone} onChange={this.handlePhone} />Phone</div>
                        </div>
                        {emailContent}
                        {phoneContent}
                        <div><input type="text" placeholder="Subject" className="contact-input" name="subject" required /></div>
                        <div><textarea placeholder="Enter your message..." className="contact-message" rows="8" name="message" required /></div>
                        <div><button type="submit" className="contact-submit">Submit</button></div>
                    </form>
                    <div>{this.state.message}</div>
                </div >
            </div >
        )
    }
}

export default ContactForm;

/*
<div className="contact-reply">
                        <div>Is it okay to leave a message? <input type="checkbox" className="contact-checkbox" checked={this.state.contact} name="contact" onChange={this.handleContact}/></div>
                    </div>
*/