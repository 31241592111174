import React from 'react';
import './App.css';
import ReactCardFlip from 'react-card-flip';
import Topbar from './Topbar';
import Footer from './Footer';
import { AnimatePresence, motion } from 'framer-motion';

class FAQ extends React.Component {

    constructor() {
        super();
        this.state = {
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            q6: false,
            q7: false,
            q8: false,
            q9: false,
            q10: false
        };
        this.q1Flip = this.q1Flip.bind(this);
        this.q2Flip = this.q2Flip.bind(this);
        this.q3Flip = this.q3Flip.bind(this);
        this.q4Flip = this.q4Flip.bind(this);
        this.q5Flip = this.q5Flip.bind(this);
        this.q6Flip = this.q6Flip.bind(this);
        this.q7Flip = this.q7Flip.bind(this);
        this.q8Flip = this.q8Flip.bind(this);
        this.q9Flip = this.q9Flip.bind(this);
        this.q10Flip = this.q10Flip.bind(this);
    }

    q1Flip(e) {
        e.preventDefault();
        this.setState(prevState => ({ q1: !prevState.q1 }));
    }

    q2Flip(e) {
        e.preventDefault();
        this.setState(prevState => ({ q2: !prevState.q2 }));
    }

    q3Flip(e) {
        e.preventDefault();
        this.setState(prevState => ({ q3: !prevState.q3 }));
    }

    q4Flip(e) {
        e.preventDefault();
        this.setState(prevState => ({ q4: !prevState.q4 }));
    }

    q5Flip(e) {
        e.preventDefault();
        this.setState(prevState => ({ q5: !prevState.q5 }));
    }

    q6Flip(e) {
        e.preventDefault();
        this.setState(prevState => ({ q6: !prevState.q6 }));
    }

    q7Flip(e) {
        e.preventDefault();
        this.setState(prevState => ({ q7: !prevState.q7 }));
    }

    q8Flip(e) {
        e.preventDefault();
        this.setState(prevState => ({ q8: !prevState.q8 }));
    }

    q9Flip(e) {
        e.preventDefault();
        this.setState(prevState => ({ q9: !prevState.q9 }));
    }

    q10Flip(e) {
        e.preventDefault();
        this.setState(prevState => ({ q10: !prevState.q10 }));
    }

    render() {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <Topbar />
                <div className="header-image3">
                    <div className="services1">FAQ</div>
                </div>
                <div>
                    <div className="faq-container">
                        <ReactCardFlip isFlipped={this.state.q1} flipDirection="vertical">
                            <button onClick={this.q1Flip} className="faq-card">
                                <div className="faq-question">
                                    What is psychotherapy?
                                </div>
                            </button>

                            <button onClick={this.q1Flip} className="faq-card2">
                                <div className="faq-answer">
                                    Psychotherapy, also known as “talk therapy” is a treatment approach used to help individual’s address concerns related to their mental health, or to maintain emotional and psychological wellbeing. For some individuals, psychotherapy may help decrease anxiety or depression symptoms, work through past traumatic experiences, learn coping tools to manage daily life, or cope with loss and grief.
                                </div>
                            </button>
                        </ReactCardFlip>
                    </div>
                    <div className="faq-container">
                        <ReactCardFlip isFlipped={this.state.q2} flipDirection="vertical">
                            <button onClick={this.q2Flip} className="faq-card">
                                <div className="faq-question">
                                    Do I need a referral?
                                </div>
                            </button>

                            <button onClick={this.q2Flip} className="faq-card2">
                                <div className="faq-answer">
                                    A referral from a family doctor or other healthcare professional is not required. However, it can be beneficial for professionals in your circle of care to connect regarding your treatment. If you would like me to follow up with another care provider, we can discuss this together in session.
                                </div>
                            </button>
                        </ReactCardFlip>
                    </div>
                    <div className="faq-container">
                        <ReactCardFlip isFlipped={this.state.q3} flipDirection="vertical">
                            <button onClick={this.q3Flip} className="faq-card">
                                <div className="faq-question">
                                    I am interested in services. What are the next steps I should take?
                                </div>
                            </button>

                            <button onClick={this.q3Flip} className="faq-card2">
                                <div className="faq-answer">
                                    It can be a big step to reach out and ask for help. If you are interested in services, you can fill out the “Contact Me” form on my website, email me at tracy@talltreepsychotherapy.ca, or call me at 647-577-9055. I will reach out to set up a brief initial intake call to hear about the challenges or difficulties you are facing and to tell you a bit about myself.  There is no fee for this call, and you are not obligated to move forward with services. At the end of this call, we can discuss next steps.
                                </div>
                            </button>
                        </ReactCardFlip>
                    </div>
                    <div className="faq-container">
                        <ReactCardFlip isFlipped={this.state.q4} flipDirection="vertical">
                            <button onClick={this.q4Flip} className="faq-card">
                                <div className="faq-question">
                                    What are the risks and benefits of Psychotherapy?
                                </div>
                            </button>

                            <button onClick={this.q4Flip} className="faq-card2">
                                <div className="faq-answer">
                                    For some people, sharing private aspects of their life can seem overwhelming, and psychotherapy can elicit difficult emotions, memories, or feelings that you may typically attempt to avoid. You may begin to acknowledge parts of yourself that are tough to explore, or be pushed outside your comfort zone.
                                    However, psychotherapy can also help individuals gain a better understanding of themselves, develop more effective coping tools, or improve interpersonal relationships and communication, and you may experience a decrease in anxious, depressive, anger, or trauma symptoms.
                                </div>
                            </button>
                        </ReactCardFlip>
                    </div>
                    <div className="faq-container">
                        <ReactCardFlip isFlipped={this.state.q5} flipDirection="vertical">
                            <button onClick={this.q5Flip} className="faq-card">
                                <div className="faq-question">
                                    What can I expect from a psychotherapy session?
                                </div>
                            </button>

                            <button onClick={this.q5Flip} className="faq-card2">
                                <div className="faq-answer">
                                    The first therapy session is typically 60 to 75 minutes in length. During this time, I will learn more about your current concerns, history of the problem, relationships, physical and emotional health, and other important information. We will also take some time to develop goals for therapy. Subsequent sessions are 50 minutes in length and will focus on the established treatment plan, utilizing the therapeutic approach appropriate to the clients' needs.
                                </div>
                            </button>
                        </ReactCardFlip>
                    </div>
                    <div className="faq-container">
                        <ReactCardFlip isFlipped={this.state.q6} flipDirection="vertical">
                            <button onClick={this.q6Flip} className="faq-card">
                                <div className="faq-question">
                                    Do you provide virtual psychotherapy sessions?
                                </div>
                            </button>

                            <button onClick={this.q6Flip} className="faq-card2">
                                <div className="faq-answer">
                                    Virtual psychotherapy sessions are provided using Zoom or through a secure video portal. While virtual therapy can provide convenience for clients to access services, there are some important things that should be considered and can be discussed during the initial intake.
                                </div>
                            </button>
                        </ReactCardFlip>
                    </div>
                    <div className="faq-container">
                        <ReactCardFlip isFlipped={this.state.q7} flipDirection="vertical">
                            <button onClick={this.q7Flip} className="faq-card">
                                <div className="faq-question">
                                    Are psychotherapy services covered by benefits?
                                </div>
                            </button>

                            <button onClick={this.q7Flip} className="faq-card2">
                                <div className="faq-answer">
                                    Most extended health benefit plans include coverage for Registered Psychotherapists. <u>However, it is the client’s responsibility to confirm with their insurance company if their benefits package covers the services of a Registered Psychotherapist</u>, as well as the coverage amount. Please note that I do not provide direct billing to insurance companies.
                                </div>
                            </button>
                        </ReactCardFlip>
                    </div>
                    <div className="faq-container">
                        <ReactCardFlip isFlipped={this.state.q8} flipDirection="vertical">
                            <button onClick={this.q8Flip} className="faq-card">
                                <div className="faq-question">
                                    What are your fees and how do I pay for sessions?
                                </div>
                            </button>

                            <button onClick={this.q8Flip} className="faq-card2">
                                <div className="faq-answer">
                                    The cost for psychotherapy services is $160.00 per 50 minute session. I provide a sliding scale on a limited basis for individuals facing financial difficulties. Please contact me prior to our initial session to discuss this further.
                                    <br /><br />
                                    I require <u>full payment</u> at each session, by <i>credit card</i> or <i>e-Transfer</i>. Once payment has been received, a receipt will be provided to you.
                                </div>
                            </button>
                        </ReactCardFlip>
                    </div>
                    <div className="faq-container">
                        <ReactCardFlip isFlipped={this.state.q9} flipDirection="vertical">
                            <button onClick={this.q9Flip} className="faq-card">
                                <div className="faq-question">
                                    What is your cancellation policy?
                                </div>
                            </button>

                            <button onClick={this.q9Flip} className="faq-card2">
                                <div className="faq-answer">
                                    If you need to cancel a session, I require 24 hours notice by phone or by email. Missed sessions or late cancellations will result in a $50.00 cancellation fee. In some cases, the cancellation fee may be waived in the event of a medical or family emergency, or inclement weather.
                                </div>
                            </button>
                        </ReactCardFlip>
                    </div>
                    <div className="faq-container">
                        <ReactCardFlip isFlipped={this.state.q10} flipDirection="vertical">
                            <button onClick={this.q10Flip} className="faq-card">
                                <div className="faq-question">
                                    Is my private information kept confidential?
                                </div>
                            </button>

                            <button onClick={this.q10Flip} className="faq-card2">
                                <div className="faq-answer">
                                    Information shared between the therapist and the client is kept strictly confidential. I will not disclose any information to a third party, unless I have your prior written authorization, and you have the right to withdraw or make changes to this consent at any time. However, there are limits to confidentiality in which I am legally and ethically obligated to release personal information about my client. These limits are outlined in the Informed Consent form that is emailed to you before your first session, and they are discussed together during your first appointment.
                                </div>
                            </button>
                        </ReactCardFlip>
                    </div>
                </div>
                <Footer />
            </motion.div>
        )
    }
}

export default FAQ;

/*
                <div>
                    <div className="faq-container">
                    <ReactCardFlip isFlipped={this.state.q1} flipDirection="vertical">
                        <button onClick={this.q1Flip} className="faq-card">
                            <div className="faq-question">
                                What is your dog's name?
                            </div>
                        </button>

                        <button onClick={this.q1Flip} className="faq-card2">
                            <div className="faq-answer">
                                My dog's name is Mara. She is a good doggy.
                            </div>
                        </button>
                    </ReactCardFlip>
                    </div>
                    <div className="faq-container">
                    <ReactCardFlip isFlipped={this.state.q2} flipDirection="vertical">
                        <button onClick={this.q2Flip} className="faq-card">
                            <div className="faq-question">
                                What is your cat's name?
                            </div>
                        </button>

                        <button onClick={this.q2Flip} className="faq-card2">
                            <div className="faq-answer">
                                My cat's name is Georgia. She is a good kitty cat.
                            </div>
                        </button>
                    </ReactCardFlip>
                    </div>
                    <div className="faq-container">
                    <ReactCardFlip isFlipped={this.state.q3} flipDirection="vertical">
                        <button onClick={this.q3Flip} className="faq-card">
                            <div className="faq-question">
                                What does a very long question with a lot of words look like with this layout?
                            </div>
                        </button>

                        <button onClick={this.q3Flip} className="faq-card2">
                            <div className="faq-answer">
                            Psychotherapy (also psychological therapy or talking therapy) is the use of psychological methods, particularly when based on regular personal interaction with adults, to help a person change behavior and overcome problems in desired ways. Psychotherapy aims to improve an individual's well-being and mental health, to resolve or mitigate troublesome behaviors, beliefs, compulsions, thoughts, or emotions, and to improve relationships and social skills.
                            </div>
                        </button>
                    </ReactCardFlip>
                    </div>
                    <div className="faq-container">
                    <ReactCardFlip isFlipped={this.state.q4} flipDirection="vertical">
                        <button onClick={this.q4Flip} className="faq-card">
                            <div className="faq-question">
                                Who is Jake Briltz?
                            </div>
                        </button>

                        <button onClick={this.q4Flip} className="faq-card2">
                            <div className="faq-answer">
                                Jake Briltz is the coolest guy I know.
                            </div>
                        </button>
                    </ReactCardFlip>
                    </div>
                </div>
                */