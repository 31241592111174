import React from 'react';
import './App.css';
import MayaPic from './Maya.JPEG';
import pic1 from './About3.jpg';
import Topbar from './Topbar';
import Footer from './Footer';
import { motion } from 'framer-motion';
import { maya1, maya2 } from './TextContent.json';
import { Link } from 'react-router-dom';

class Maya extends React.Component {
    render() {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <Topbar />
                <div className="header-image5">
                    <div className="services1">Maya Oakes</div>
                </div>
                <div className='profile-container'>
                    <div className='profile-section'>
                        <img src={MayaPic} alt="Maya Oakes" className='profile-pic' />
                        <div className='profile-overview-section'>
                            <div className='profile-header'>Overview</div>
                            <div className='profile-overview'>Populations Served</div>
                            <ul className='overview-list'>
                                <li>Children</li>
                                <li>Adolescents</li>
                                <li>Adults</li>
                            </ul>
                            <div className='profile-overview'>Therapy Formats</div>
                            <ul className='overview-list'>
                                <li>Individual</li>
                                <li>Family</li>
                            </ul>
                            <div className='profile-overview'>Main Areas of Focus</div>
                            <ul className='overview-list'>
                                <li>Anxiety</li>
                                <li>Life Transitions</li>
                                <li>Relationship Issues</li>
                            </ul>
                        </div>
                    </div>
                    <br />
                    <div class="profile-bio">
                        <div>
                            <p className='profile-paragraph'>{maya1}</p>
                            <p className='profile-paragraph'>{maya2}</p>
                            <p className='profile-paragraph'>**Maya provides services <i>virtually</i></p>
                            <div class="services-button"><Link to='/contact' className="contact-submit contact-me">Interested in services? Click here</Link></div>
                        </div>
                        <img src={pic1} alt="image" className="image2 desktop" />
                    </div><br></br><br></br>
                </div>
                <Footer />
            </motion.div>
        )
    }
}

export default Maya;