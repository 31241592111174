import React from 'react';
import './App.css';
import Form from './ContactForm';
import Topbar from './Topbar';
import Footer from './Footer';
import Map from './GoogleMap';
import { AnimatePresence, motion } from 'framer-motion';

class Contact extends React.Component {

    render() {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <Topbar />
                <div className="header-image6">
                    <div className="services1">Contact</div>
                </div>
                <div className="contact-container">
                    <Form />
                    <div>
                        <Map />
                        <div className="contact-info-container">
                            <div className="contact-info">Office Address:</div>
                            <div className="contact-info">556 O’Connor Drive, Suite 117</div>
                            <div className="contact-info">Kingston, ON. K7P 1N3</div>
                            <br />
                            <div className="contact-info">Phone: 647-577-9055</div>
                            <div className="contact-info">Email: info@talltreepsychotherapy.ca</div>
                        </div>
                    </div>
                </div>
                <Footer />
            </motion.div>
        )
    }
}

export default Contact;

/*
<div>
                        <Map />
                        <div className="contact-info-container">
                        <div className="contact-info">Office Address:</div>
                        <div className="contact-info">2300 Yonge Street, Suite 1600</div>
                        <div className="contact-info">Toronto, ON M4P 1E4</div>
                        <br />
                        <div className="contact-info">Phone: 416-995-8006</div>
                        <div className="contact-info">Email: info@talltreepsychotherapy.ca</div>
                        <br />
                        <div className="contact-info">Office located on the northwest corner of Yonge St and Eglinton Ave</div>
                        <div className="contact-info">Accessible by TTC subway and bus (Eglinton Station)</div>
                        <div className="contact-info">Paid parking available </div>
                        </div>
                    </div>
                    */