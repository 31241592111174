import React from 'react';
import './App.css';
import Topbar from './Topbar';
import Footer from './Footer';
import { motion } from 'framer-motion';

class Solmaz extends React.Component {
    render() {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <Topbar />
                <div className="header-image5">
                    <div className="services1">Solmaz Barghgir</div>
                </div>
                <h1>Coming Soon</h1>
                <Footer />
            </motion.div>
        )
    }
}

export default Solmaz;