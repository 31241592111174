import React from 'react';
import './App.css';
import pic1 from './Services4.jpg';
import pic2 from './FAQ1.jpg';
import Topbar from './Topbar';
import Footer from './Footer';
import { AnimatePresence, motion } from 'framer-motion';
import { services1, services2, services3, services4, services5 } from './TextContent.json';
import { Link } from 'react-router-dom';

class Services extends React.Component {
    render() {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <Topbar />
                <div className="header-image">
                    <div className="services1">Services</div>
                </div>
                <div className="services-container">
                    <div>
                        <div className="paragraph1">
                            Psychotherapy services can address the following concerns:
                        </div>
                        <div className="paragraph1">
                            <ul>
                                <li>Trauma and Posttraumatic Stress Disorder</li>
                                <li>Attention Deficit Hyperactivity Disorder (ADHD)</li>
                                <li>Anxiety, worry, and panic</li>
                                <li>Depression</li>
                                <li>Emotion Regulation</li>
                                <li>Anger Management</li>
                                <li>Grief and Loss</li>
                                <li>Life Transitions</li>
                                <li>Social Skills</li>
                                <li>Assertive Communication</li>
                                <li>Boundaries</li>
                                <li>Self-Esteem</li>
                                <li>Stress</li>
                                <li>Behavioural Concerns</li>
                                <li>Suicidal Ideation</li>
                                <li>Autism Spectrum Disorder</li>
                                <li>Self-Harm</li>
                                <li>Bipolar Disorder</li>
                                <li>Obsessive-Compulsive Disorder (OCD)</li>
                                <li>Personality Disorders</li>
                            </ul>
                        </div>
                    </div>
                    <img src={pic1} alt="image" width="40%" className="image1 serv-img" />
                </div>
                <div className="services-container">
                    <img src={pic2} alt="image" width="40%" className="image1 dis-mob" />
                    <div>
                        <div className="paragraph1">
                            Tracy provides services <i>in person</i> and <i>virtually</i>
                        </div>
                        <div className="paragraph1">
                            Stacey provides services<i>virtually</i>
                        </div>
                        <div className="paragraph1">
                            Virtual sessions are conducted using PIPEDA and PHIPA compliant platforms (Jane Video or Zoom)
                        </div>
                        <div className="paragraph1">
                            Contact if you would like to schedule an appointment
                        </div>
                        <br /><br />
                        <div className="about-header">
                            Clinical Supervision
                        </div>
                        <div className="paragraph1">
                            Clinical supervision may be available to students completing their master degree practicums or for registered psychotherapists and social workers.
                        </div>
                        <div><Link to='/contact' className="contact-submit contact-me">Interested in services? Click here</Link></div>
                    </div>
                </div>
                <Footer />
            </motion.div>
        )
    }
}

export default Services;

//<div><a href='https://talltreepsychotherapy.therabyte.app/site' className="contact-submit contact-me booked">Already booked? Click here</a></div>