import './App.css';
import RouterPage from './RouterPage';
import { AnimatePresence, motion } from 'framer-motion';

function App() {
  return (
    <div>
      <RouterPage />
    </div>
  );
}

export default App;
