import React from 'react';
import './App.css';
import { AnimatePresence, motion } from 'framer-motion';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyle = {
    width: '100%',
    height: '100%',
};

const containerStyle = {
    width: '100%',
    height: '100%',
    position: 'relative'
};

class GoogleMap extends React.Component {
    render() {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <div className="map">
                    <Map
                        google={this.props.google}
                        zoom={15}
                        style={mapStyle}
                        containerStyle={containerStyle}
                        initialCenter={{ lat: 44.260280, lng: -76.565050 }}
                    >
                        <Marker position={{ lat: 44.260280, lng: -76.565050 }} />
                    </Map>
                </div>
            </motion.div>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAwWrO76i4f3-ArSDFsrsrNgxc_s304Ueg'
})(GoogleMap);