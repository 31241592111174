import React from 'react';
import './App.css';
import TracyPic from './Tracy.jpg';
import Topbar from './Topbar';
import Footer from './Footer';
import { motion } from 'framer-motion';
import { tracy1, tracy2 } from './TextContent.json';
import { Link } from 'react-router-dom';
import pic2 from './About2.jpg';

class Tracy extends React.Component {
    render() {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <Topbar />
                <div className="header-image5">
                    <div className="services1">Tracy Bertrim</div>
                </div>
                <div className='profile-container'>
                    <div className='profile-section'>
                        <img src={TracyPic} alt="Tracy Bertrim" className='profile-pic' />
                        <div className='profile-overview-section'>
                            <div class="profile-header">Overview</div>
                            <div className='profile-overview'>Populations Served</div>
                            <ul className='overview-list'>
                                <li>Children</li>
                                <li>Adolescents</li>
                                <li>Adults</li>
                            </ul>
                            <div className='profile-overview'>Therapy Formats</div>
                            <ul className='overview-list'>
                                <li>Individual</li>
                                <li>Family</li>
                            </ul>
                            <div className='profile-overview'>Main Areas of Focus</div>
                            <ul className='overview-list last-list'>
                                <li>Anxiety, Phobias</li>
                                <li>ADHD</li>
                                <li>Trauma and PTSD</li>
                                <li>First Responders and Frontline Workers</li>
                            </ul>
                        </div>
                    </div>
                    <br />
                    <div class="profile-bio">
                        <div>
                            <p className='profile-paragraph'>{tracy1}</p>
                            <p className='profile-paragraph'>{tracy2}</p>
                            <p className='profile-paragraph'>**Tracy provides both<i> in person</i> (Kingston office) and<i> virtual</i> therapy services</p>
                            <div class="services-button"><Link to='/contact' className="contact-submit contact-me">Interested in services? Click here</Link></div>
                        </div>
                        <img src={pic2} alt="image" className="image2 desktop" />
                    </div><br></br><br></br>
                </div>
                <Footer />
            </motion.div>
        )
    }
}

export default Tracy;